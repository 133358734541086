 


<section style="background-color: black;" *ngIf="login">
  <div class="background">
    <div class="shape"></div>
    <div class="shape"></div> 
  </div>
  <form method="post" #formData="ngForm">
    <h4> <b><i> PHARMAXELLENCE TOURNAMENT </i></b>
     </h4>
  
    <label for="username">Username</label>
    <input name="username" type="text" id="login_username" ngModel placeholder="Username"  >
  
    <label for="password">Password</label>
    <input name="password" type="password" id="login_password" ngModel placeholder="password">
  
    <button style="    margin-top: 50px;
    width: 100%;
    background-color: #ffffff;
    color: #080710;
    padding: 15px 0;
    font-size: 18px;
    font-weight: 600;
    border-radius: 5px;
    cursor: pointer;" type="submit"   (click)="checkLogin(formData)">Log In</button>
   
   <div style="text-align: center; margin-top: 20px;">
    <button (click)="registration()" 
    
    style="    margin-top: 50px;
    width: 100%;
    background-color: #000000;
    color: #ffffff;
    padding: 15px 0;
    font-size: 18px;
    font-weight: 600;
    border-radius: 5px;
    cursor: pointer; text-decoration: underline;"
    > Click here to register</button>
  </div>


  <ul>
    <li>Enroll Your self For Competition.</li>
    <li>Login With Email And Passward As You Entered At The Time Of registration.</li>
    <li>Select 10 Generics Names Of Your Choice From The Button <span style="color: rgb(0, 236, 12);">"Select Your Generics"</span></li>
    <li>Search Product Details From Internet Or Diff. Website And Enter it In Product Form</li>
  </ul>
  <marquee   direction="left"><b>Registration Open Till 4/10/2024 11:59 PM //Compitition Start Date 05/10/2024 12:00 AM And Ends On 15/10/2024 12:00 AM</b></marquee>

  <span style="color: red;">Project By Quality Excellance & Research Pune Sponsered By <a href="https://cyclonepharma.com/" target="_blank">Cyclone Pharma Pune</a>   Contact : 8087500550</span>

    
  </form>

</section>
<section style="background-color: black;" *ngIf="!login">
  <div class="background">
    <div class="shape"></div>
    <div class="shape"></div> 
  </div>
  <form method="post" #RegiData="ngForm">

    <h4> <b><i>Student Registration</i></b>
     </h4>
  
    <label for="firstname">Enter Your Name</label>
    <input name="firstname" type="text" id="firstname" ngModel placeholder="Student Name"  required>
  
    <label for="emp_email">Enter Your Email</label>
    <input name="emp_email" type="email" id="emp_email" ngModel required placeholder="Enter Your Email">
  
    <label for="contact_no">Enter Your Mobile Number</label>
    <input name="contact_no" type="text" id="contact_no" ngModel required placeholder="Enter Your Mobile Number">

    <label for="password">Password</label>
    <input name="password" type="password" id="login_password" ngModel placeholder="Password">

    <label for="year">Choose year</label>
     <select  id="year"  name="year" ngModel  required>
      <option style="background-color: black;" disabled selected value="">Choose Year</option>
      <option style="background-color: black;" value="1st">1st Year</option>
      <option style="background-color: black;" value="2nd">2nd Year</option>
      <option style="background-color: black;" value="3rd">3rd Year</option>
      <option style="background-color: black;" value="4th">4th Year</option>
    </select>
    <label for="department">Choose Course</label>
     <select  id="department"  name="department" ngModel  required>
      <option style="background-color: black;" disabled selected value="">Choose Course:</option>
      <option style="background-color: black;" value="D.Pharmacy">D.Pharmacy</option>
      <option style="background-color: black;" value="B.Pharmacy">B.Pharmacy</option>
    </select>
  
    <button style="    margin-top: 50px;
    width: 100%;
    background-color: #ffffff;
    color: #080710;
    padding: 15px 0;
    font-size: 18px;
    font-weight: 600;
    border-radius: 5px;
    cursor: pointer;" type="submit"   (click)="registrationStudent(RegiData)">Submit</button>
   
   
    <button (click)="registration()" 
    
    style="    margin-top: 50px;
    width: 100%;
    background-color: #000000;
    color: #ffffff;
    padding: 15px 0;
    font-size: 18px;
    font-weight: 600;
    border-radius: 5px;
    cursor: pointer; text-decoration: underline;"
    > Click here to Login</button>
  
    
  </form>
</section> 