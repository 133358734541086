<div class="clr-row">


  <marquee *ngIf="remCount > 0" direction="left" style="">Eligibility For Certificate ( Remaining Product Count <b style="color: red;">{{remCount}}</b> )</marquee>
 
   <div class="clr-col-lg-3 clr-col-md-3 clr-col-sm-12 clr-col-xs-12">
    <div class="contestant-card"> 
      <div class="name">Total Participant {{totalPat}}</div>
      <!-- <div class="name">{{student_name}}</div>
      <div class="count">{{product_count}}</div> -->
    </div> 
   </div>
   <div class="clr-col-lg-3 clr-col-md-3 clr-col-sm-12 clr-col-xs-12">
    <div class="contestant-card"> 
      <div class="name">Welcome {{student_name}}</div>
      <!-- <div class="name">{{student_name}}</div>
      <div class="count">{{product_count}}</div> -->
    </div>
   </div>
   <div class="clr-col-lg-3 clr-col-md-3 clr-col-sm-12 clr-col-xs-12">
    <div class="contestant-card"> 
      <div class="name">Your Product Count</div>
      <div class="count">{{product_count}}</div>
      <!-- <div class="name">{{student_name}}</div>
      <div class="count">{{product_count}}</div> -->
    </div>
   </div>
 
  <div class="clr-col-lg-3 clr-col-md-3 clr-col-sm-12 clr-col-xs-12">
    <div class="contestant-card"> 
      <div class="name">Your Rank</div>
      <div class="rank">🥇 {{student_rank}}</div>

      <!-- <div class="name">{{student_name}}</div>
      <div class="count">{{product_count}}</div> -->
    </div>
  </div>
  <div class="clr-col-lg-12 clr-col-md-12 clr-col-sm-12 clr-col-xs-12"></div>

 
  </div>
  

  <div class="container-fluid" *ngIf="isTable">
    <div class="card">
      <div class="card-header">
        <div class="clr-row" style="margin-bottom: -10px;">
          <div class="clr-col-lg-6" style="color: rgb(255, 255, 255);margin-top: -2px;text-align: left;">Products List</div>
          <div class="clr-col-lg-6" style="text-align: right;padding-right: 0px;margin-top: -7px;">
            <button id="isAddNew" class="btn btn-danger btn-sm" (click)="isTable = false; isForm = true;">Add New
              Product</button>
              <button class="btn btn-sm btn-success"  routerLink="/main/mappdata"> Select Your Generics </button>
          </div>
        </div>
      </div>
      <div class="card-body">
    
        <div class="table-wrapper-scroll-y">
          <table class="table  table-border" style="margin-top: 10px;">
          
              <tr>
                <th style="background-color: aquamarine;font-weight: bolder;">Sr.No.</th>
                <th style="background-color: aquamarine;font-weight: bolder; width: 15rem;text-align: left;">Name</th>
                <th style="background-color: aquamarine;font-weight: bolder; width: 6rem;text-align: left;">Pack Size</th>
                <th style="background-color: aquamarine;font-weight: bolder; width: 7rem;text-align: left;">Category</th>
                <th style="background-color: aquamarine;font-weight: bolder; text-align: left;">MRP</th>
                <th style="background-color: aquamarine;font-weight: bolder; text-align: left;">Form</th>
                <th style="background-color: aquamarine;font-weight: bolder; text-align: left;">Manufacturer Name</th>
                <th style="background-color: aquamarine;font-weight: bolder; text-align: left;">Generic</th>
                <!-- <th style="text-align: left;">Action</th> -->
              </tr>
            
              <tr *ngFor="let list of distList; let i = index">
                <td>{{ i+1 }}</td>
                <td style="text-align: left;">{{ list.p_name }}</td>
                <td style="text-align: left;">{{ list.pack_size }}</td>
                <td style="text-align: left;">{{ list.p_cat }}</td>
                <td style="text-align: left;">{{ list.mrp }}</td>
                <td style="text-align: left;">{{ list.d_form }}</td>
                <td style="text-align: left;">{{ list.m_name }}</td>
                <td style="text-align: left;">{{ list.generic }}</td>
                <!-- <td><button class="btn btn-sm btn-info" (click)="edit(i)">Edit</button></td> -->
              </tr>
             
          </table>
        </div>
      </div>
    </div>
  </div>
  
  <div class="container-fluid" *ngIf="isForm">
    <div class="card">
       <div class="card-header">
        <div class="clr-row" style="margin-bottom: -10px;">
          <div class="clr-col-lg-9" style="color: rgb(255, 255, 255);margin-top: -2px;text-align: left;">Add Product</div>
          <div class="clr-col-lg-3" style="text-align: right;padding-right: 0px;margin-top: -7px;">
            <!-- <button  class="btn btn-success btn-sm"  routerLink="/main/mappdata">Select Generics To Add Product</button> -->
          </div>
        </div>
      </div>
      <div class="card-block">
        <form #productForm="ngForm" method="post" enctype="multipart/form-data">
          <div class="clr-row">
            <div class="clr-col-lg-3 clr-col-md-3 clr-col-sm-12 clr-col-xs-12">
              <label>Product Name</label>
              <input type="text" ngModel name="p_name" class="form-control" placeholder="Enter Product Name" autofocus
                required>
            </div>
            <div class="clr-col-lg-3 clr-col-md-3 clr-col-sm-12 clr-col-xs-12">
              <label>Generic</label>
              <select name="generic" [(ngModel)]="generic" required >
                <option></option>
                 <option *ngFor="let item of generics;">{{item.value}}</option>
              </select>  
            </div>
            <div class="clr-col-lg-3 clr-col-md-3 clr-col-sm-12 clr-col-xs-12">
              <label>Schedule</label>
      
              <select name="p_cat" [(ngModel)]="p_cat" required >
                <option></option>
                 <option *ngFor="let item of categories;">{{item.value}}</option>
              </select>  
            </div>
         
            <div class="clr-col-lg-3 clr-col-md-3 clr-col-sm-12 clr-col-xs-12">
              <label> Pack Size</label>
              <select name="pack_size" [(ngModel)]="pack_size" required >
                <option></option>
                 <option *ngFor="let item of packsizes;">{{item.value}}</option>
              </select>  
            </div>
            <div class="clr-col-lg-3 clr-col-md-3 clr-col-sm-12 clr-col-xs-12">
              <label>Dosage</label>
    
              <select name="d_form" [(ngModel)]="d_form" required >
                <option></option>
                 <option *ngFor="let item of dosages;">{{item.value}}</option>
              </select>  
            </div>
            <div class="clr-col-lg-3 clr-col-md-3 clr-col-sm-12 clr-col-xs-12">
              <label>MRP</label>
              <input type="text" ngModel name="mrp" class="form-control" placeholder="Enter MRP" required>
            </div>
          
            <div class="clr-col-lg-3 clr-col-md-3 clr-col-sm-12 clr-col-xs-12">
              <label>Manufacturer Name</label>
              <select name="m_name" [(ngModel)]="m_name" required  (change)="checkData($event.target.value)">
                <option></option>
                <option style="color: red;font-weight: bolder;">Add If Not Found</option>
                 <option *ngFor="let item of manufactures;">{{item.value}}</option>
              </select> 
            </div>
            <div  class="clr-col-lg-3 clr-col-md-3 clr-col-sm-12 clr-col-xs-12">
              <label>Drug Category (Pathy)</label>
              <select name="drugClass" class="form-control" placeholder="Select Drug Class" ngModel required>
                <option>ALLOPATHIC</option>
                <option>ALLOPATHIC (Ethical)</option>
                <option>ALLOPATHIC (Generic)</option>
                <option>ALLOPATHIC (PCD)</option>
                <option>ALLOPATHIC (Other)</option>
                <option>AYURVEDIC</option>
                <option>AYURVEDIC (Branded)</option>
                <option>AYURVEDIC (Other)</option>
                <option>COSMATICS</option>
                <option>COSMATICS (Branded)</option>
                <option>COSMATICS (Generic/Market)</option>
                <option>SURGICAL</option>
                <option>SURGICAL (Equipment)</option>
                <option>SURGICAL (IV/Hosp)</option>
                <option>SURGICAL (Common)</option>
                <option>NUTRA/FOOD</option>
                <option>NUTRA/FOOD (Speciality)</option>
                <option>VETERINORY</option>
                <option>VETERINORY (Branded)</option>
                <option>VETERINORY (Generic)</option>
                <option>VETERINORY (Feed/Suppliment)</option>
                <option>HOMEOPATHIC</option>
                <option>HOMEOPATHIC (Generic)</option>
                <option>PCD</option>
                <option>PCD1</option>
                <option>PCD2</option>
                <option>PCD3</option>
             </select>
   
            </div>


          </div>
        </form>
      </div>
      <div class="card-footer">
        <button type="submit" class="btn btn-sm btn-primary" (click)="productForm.form.valid && saveProduct(productForm)"
          [disabled]="productForm.form.invalid"><i class="fa fa-dot-circle-o"></i> Submit</button>&nbsp;&nbsp;
        <button type="submit"  class="btn btn-sm btn-danger" (click)="close()">Close</button>
      </div>
    </div>
  </div>
  
  <div class="container-fluid" *ngIf="isManu">
    <div class="card">
       <div class="card-header">
        <div class="clr-row" style="margin-bottom: -10px;">
          <div class="clr-col-lg-9" style="color: rgb(255, 255, 255);margin-top: -2px;text-align: left;">ADD Manufacturer</div>
          <div class="clr-col-lg-3" style="text-align: right;padding-right: 0px;margin-top: -7px;">
           </div>
        </div>
      </div>
      <div class="card-block">
        <form #manfForm="ngForm" method="post" enctype="multipart/form-data" class="clr-row">

          <div class="clr-col-lg-3 clr-col-md-3 clr-col-sm-12 clr-col-xs-12">
            <label>Manufacturer Name</label>
            <input type="text" ngModel name="m_name"  class="form-control" placeholder="Enter Manufacturer Name" autofocus required>
          </div>

          <!-- <div class="clr-col-lg-3 clr-col-md-3 clr-col-sm-12 clr-col-xs-12">
            <label>Short Code</label>
            <input type="text" ngModel name="s_code"  class="form-control" placeholder="Enter Scode"  >
          </div> -->
 
        </form>
      </div>
      <div class="card-footer">
        <button type="submit" class="btn btn-sm btn-primary" (click)="manfForm.form.valid && saveManf(manfForm)" [disabled]="manfForm.form.invalid"><i class="fa fa-dot-circle-o"></i> Submit</button>&nbsp;&nbsp;
        <button type="reset" class="btn btn-sm btn-danger"  (click)="isManu = false; getmanfDetails();isForm = true;"><i class="fa fa-ban"></i> Close</button>
      </div>
    </div>
  </div>

  
  
  
  