<div class="clr-row">


    <marquee *ngIf="remCount > 0" direction="left" style="">Eligibility For Certificate ( Remaining Product Count <b style="color: red;">{{remCount}}</b> )</marquee>
   
     <div class="clr-col-lg-3 clr-col-md-3 clr-col-sm-12 clr-col-xs-12">
      <div class="contestant-card"> 
        <div class="name">Total Participant {{totalPat}}</div>
        <!-- <div class="name">{{student_name}}</div>
        <div class="count">{{product_count}}</div> -->
      </div> 
     </div>
     <div class="clr-col-lg-3 clr-col-md-3 clr-col-sm-12 clr-col-xs-12">
      <div class="contestant-card"> 
        <div class="name">Welcome ADMIN</div>
        <!-- <div class="name">{{student_name}}</div>
        <div class="count">{{product_count}}</div> -->
      </div>
     </div>
     <div class="clr-col-lg-3 clr-col-md-3 clr-col-sm-12 clr-col-xs-12">
      <div class="contestant-card"> 
        <div class="name">total Product Count</div>
        <div class="count">{{totalProdCount}}</div>
        <!-- <div class="name">{{student_name}}</div>
        <div class="count">{{product_count}}</div> -->
      </div>
     </div>
   
    <div class="clr-col-lg-3 clr-col-md-3 clr-col-sm-12 clr-col-xs-12">
      <div class="contestant-card"> 
        <div class="name">Your Rank</div>
        <div class="rank">🥇 Always On 1</div>
  
        <!-- <div class="name">{{student_name}}</div>
        <div class="count">{{product_count}}</div> -->
      </div>
    </div>
    <div class="clr-col-lg-12 clr-col-md-12 clr-col-sm-12 clr-col-xs-12"></div>
  
   
    </div>
    
  
    <div class="container-fluid" *ngIf="isTable">
      <div class="card">
        <div class="card-header">
          <div class="clr-row" style="margin-bottom: -10px;">
            <div class="clr-col-lg-6" style="color: rgb(255, 255, 255);margin-top: -2px;text-align: left;">Rank List</div>
            <div class="clr-col-lg-6" style="text-align: right;padding-right: 0px;margin-top: -7px;">
              <button id="isAddNew" class="btn btn-danger btn-sm" (click)="isTable = false; isForm = true;">View Products</button>
             </div>
          </div>
        </div>
        <div class="card-body">
      
          <div class="table-wrapper-scroll-y">
            <table class="table  table-border" style="margin-top: 10px;">
            
                <tr>
                  <th style="background-color: aquamarine;font-weight: bolder;">Rank</th>
                  <th style="background-color: aquamarine;font-weight: bolder; text-align: left;">Student Name</th>
                  <th style="background-color: aquamarine;font-weight: bolder; text-align: left;">Product Count</th>
                 </tr>
              
                <tr *ngFor="let list of rankData; let i = index">
                   <td style="font-weight: bolder;">{{ list.student_rank }}</td>
                  <td style="text-align: left;">{{ list.student_name }}</td>
                  <td style="text-align: left;">{{ list.product_count }}</td>
                 </tr>
               
            </table>
          </div>
        </div>
      </div>
    </div>
    
   
    <div class="container-fluid" *ngIf="isForm">
        <div class="card">
           <div class="card-header">
            <div class="clr-row" style="margin-bottom: -10px;">
              <div class="clr-col-lg-9" style="color: rgb(255, 255, 255);margin-top: -2px;text-align: left;">Products List</div>
              <div class="clr-col-lg-3" style="text-align: right;padding-right: 0px;margin-top: -7px;">
                <button type="submit"  class="btn btn-sm btn-danger" (click)="close()">View Rank Table</button>
            </div>
            </div>
          </div>
          <div class="card-block">
            
          <div class="table-wrapper-scroll-y">
            <table class="table  table-border" style="margin-top: 10px;">
            
                <tr>
                  <th style="background-color: aquamarine;font-weight: bolder;">Sr.No.</th>
                  <th style="background-color: aquamarine;font-weight: bolder;">Student Name</th>
                  <th style="background-color: aquamarine;font-weight: bolder; width: 15rem;text-align: left;">Name</th>
                  <th style="background-color: aquamarine;font-weight: bolder; width: 6rem;text-align: left;">Pack Size</th>
                  <th style="background-color: aquamarine;font-weight: bolder; width: 7rem;text-align: left;">Category</th>
                  <th style="background-color: aquamarine;font-weight: bolder; text-align: left;">MRP</th>
                  <th style="background-color: aquamarine;font-weight: bolder; text-align: left;">Form</th>
                  <th style="background-color: aquamarine;font-weight: bolder; text-align: left;">Manufacturer Name</th>
                  <th style="background-color: aquamarine;font-weight: bolder; text-align: left;">Generic</th>
                  <!-- <th style="text-align: left;">Action</th> -->
                </tr>
              
                <tr *ngFor="let list of products1; let i = index">
                  <td>{{ i+1 }}</td>
                  <td style="text-align: left;">{{ list.firstname }}</td>
                  <td style="text-align: left;">{{ list.p_name }}</td>
                  <td style="text-align: left;">{{ list.pack_size }}</td>
                  <td style="text-align: left;">{{ list.p_cat }}</td>
                  <td style="text-align: left;">{{ list.mrp }}</td>
                  <td style="text-align: left;">{{ list.d_form }}</td>
                  <td style="text-align: left;">{{ list.m_name }}</td>
                  <td style="text-align: left;">{{ list.generic }}</td>
                  <!-- <td><button class="btn btn-sm btn-info" (click)="edit(i)">Edit</button></td> -->
                </tr>
               
            </table>
          </div>
          </div>
          <div class="card-footer">
             <button type="submit"  class="btn btn-sm btn-danger" (click)="close()">Close</button>
          </div>
        </div>
      </div>
    
   
  
    
    
    
    