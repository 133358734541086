import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataAccessService } from 'src/app/data-access.service';
declare let alertify;
 
@Component({
  selector: 'app-list-client',
  templateUrl: './list-client.component.html',
  styleUrls: ['./list-client.component.scss']
})
export class ListClientComponent implements OnInit {
  isForm = false;
  isTable = true;
  generics;
  categories;
  packsizes;
  dosages;
  manufactures;
  isEdit =  false;
  isNewGeneric = 0;
  isNewManufacturer = 0;
  selectedResult=[];
  m_name = '';
  s_code = '';
  products1: any = [];
  distList;
  p_name='';
  p_cat='';
  d_form='';

 constructor(private service: DataAccessService, private router: Router) { }

 student_rank = 0;
 product_count = 0;
 student_name = '';
 username = '';

 ngOnInit(): void {
  this.checkLogin();
  this.username = localStorage.getItem('username');
  this.getProduct();
  this.getGenericDetails();
  this.getCategoryDetails();
  this.getPacksizeDetails();
  this.getDosageDetails(); 
  this.getmanfDetails();
  this.CurrentRankapi();
  this.totalParticipant();
  }
 
  close(){
    this.isForm = false; this.getProduct(); this.isTable = true;

  }

  totalPat=0;

  getProduct() {
    this.service.get('contest.php?type=getProducts')
    .subscribe(response => {
      this.products1 = response;
      this.filterProducts();
     
    });

    
  }

  CurrentRankapi() {
    this.service.get('contest.php?type=CurrentRankapi')
    .subscribe(response => {
      this.student_rank = response['student_rank'];
      this.product_count = response['product_count'];
      this.student_name = response['student_name'];

      this.remCount =  1001 - this.product_count;
    });
  }



  totalParticipant() {
    this.service.get('contest.php?type=totalParticipant')
    .subscribe(response => {
      this.totalPat = response['totalPat'];
   
    });
  }


  checkLogin() {
    this.service.validateLogin();
  }

  remCount = 0;

  getGenericDetails() {
    this.service.get('contest.php?type=getGenericsOfStudentDetails')
    .subscribe(response => {
      this.generics = response;
    });
    
  }

  getCategoryDetails() {
    this.service.get('contest.php?type=getCategoryDetails')
    .subscribe(response => {
      this.categories = response;
    });
  }
  getPacksizeDetails() {
    this.service.get('contest.php?type=getPacksizeDetails')
    .subscribe(response => {
      this.packsizes = response;
    });
  }
  getDosageDetails() {
    this.service.get('contest.php?type=getDOSAGEDetails')
    .subscribe(response => {
      this.dosages = response;
    });
  }
  getmanfDetails() {
    this.service.get('contest.php?type=getmanfDetails')
    .subscribe(response => {
      this.manufactures = response;
    });
  }


  saveProduct(productData) {
    this.service.post('contest.php?type=saveProduct', JSON.stringify(productData.value))
     .subscribe(response => {
       if (response['status'] === 'success') {
   
         this.CurrentRankapi();
         this.totalParticipant();
         alert('Product Added Successfully.');
         productData.reset();
       } else {
         alert('Please Try Again');
       }
       },
     (error: Response) => {
       if (error.status === 400) {
         alert('An error has occurred.');
       } else {
         alert('An error has occurred, http status:' + error.status);
       }
     });
   }


   filterProducts(){
    this.distList = [];
    console.log(this.p_cat)
    console.log(this.d_form)
    for (let i = 0; i < this.products1.length; i++) {
      let prod = this.products1[i];
      if (prod['p_name'].toUpperCase().includes(this.p_name.toUpperCase()) && prod['p_cat'].toUpperCase().includes(this.p_cat.toUpperCase()) && prod['d_form'].toUpperCase().includes(this.d_form.toUpperCase())) {
        this.distList[this.distList.length] = prod;
      }
    }
  }

  isManu = false;


  checkData(value){
    if(value == 'Add If Not Found'){
        this.isManu = true;
        this.isForm = false;
    }
  }


  saveManf(customerData) {
    this.service.post('contest.php?type=saveManufacturer', JSON.stringify(customerData.value)).subscribe(response => {
      if (response['status'] === 'success') {
        this.isManu = false;
        this.isForm = true;
        this.getmanfDetails();
        this.m_name = '';
        alert('Manufacturer Added Successfully.');
      } else {
        alert('Please Try Again');
      }
      },
    (error: Response) => {
      if (error.status === 400) {
        alert('An error has occurred.');
      } else {
        alert('An error has occurred, http status:' + error.status);
      }
    });
  }


 

}













