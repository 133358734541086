import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataAccessService } from 'src/app/data-access.service';
declare let alertify;


@Component({
  selector: 'app-admindash',
  templateUrl: './admindash.component.html',
  styleUrls: ['./admindash.component.scss']
})
export class AdmindashComponent implements OnInit {

  isForm = false;
  isTable = true;
  generics;
  categories;
  packsizes;
  dosages;
  manufactures;
  isEdit =  false;
  isNewGeneric = 0;
  isNewManufacturer = 0;
  selectedResult=[];
  m_name = '';
  s_code = '';
  products1: any = [];
  distList;
  p_name='';
  p_cat='';
  d_form='';

 constructor(private service: DataAccessService, private router: Router) { }

 student_rank = 0;
 product_count = 0;
 student_name = '';
 username = '';

 ngOnInit(): void {
  this.checkLogin();
  this.username = localStorage.getItem('username');
  this.getProduct();
 
  this.CurrentRankapi();
  this.totalParticipant();
  this.CurrentALLRankapi();
  this.totalProductCount();
  }
 
  close(){
    this.isForm = false; this.getProduct(); this.isTable = true;

  }

  totalPat=0;

  getProduct() {
    this.service.get('contest.php?type=getallProducts')
    .subscribe(response => {
      this.products1 = response;
    });
  }

  CurrentRankapi() {
    this.service.get('contest.php?type=CurrentRankapi')
    .subscribe(response => {
      this.student_rank = response['student_rank'];
      this.product_count = response['product_count'];
      this.student_name = response['student_name'];

      this.remCount =  1001 - this.product_count;
    });
  }

  CurrentALLRankapi() {
    this.service.get('contest.php?type=CurrentAllRankapi')
    .subscribe(response => {
      this.rankData = response;
    
    });
  }


  rankData;


  totalParticipant() {
    this.service.get('contest.php?type=totalParticipant')
    .subscribe(response => {
      this.totalPat = response['totalPat'];
   
    });
  }


  totalProductCount() {
    this.service.get('contest.php?type=totalProductCount')
    .subscribe(response => {
      this.totalProdCount = response['totalProductCount'];
    });
  }
  totalProdCount =0

  checkLogin() {
    this.service.validateLogin();
  }

  remCount = 0;
 
 

 

}













